@font-face {
  font-family: NotoColorEmojiLimited;
  unicode-range: U+1F1E6-1F1FF;
  src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

div, canvas, * {
  font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont, 
  'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 
  'Segoe UI Emoji', 'Segoe UI Symbol';
}

.emojiFlag {
  font-family: 'NotoColorEmojiLimited', -apple-system, BlinkMacSystemFont,
}