.floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.floatingedges .react-flow__handle {
    opacity: 0;
}
