@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none !important;
}


*::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: #c0c0c5;
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
}

*::-webkit-scrollbar-button {
  display:none;
}
