@media (min-width: 0px) {
    html {
        font-size: 50%;
    }
}

@media (min-width: 490px) {
    html {
        font-size: 56.25%;
    }
}

@media (min-width: 576px) {
    html {
        font-size: 62.5%;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 68.75%;
    }
}

@media (min-width: 992px) {
    html {
        font-size: 75%;
    }
}

@media (min-width: 1200px) {
    html {
        font-size: 81.25%;
    }
}

@media (min-width: 1400px) {
    html {
        font-size: 87.5%;
    }
}

@media (min-width: 1600px) {
    html {
        font-size: 93.75%;
    }
}

@media (min-width: 1900px) {
    html {
        font-size: 100%;
    }
}
